import { getUserContext } from "./LocalStorageUserContextUtil";
import { detect } from 'detect-browser';

export const resolveHome =  () => {
    const userContext = getUserContext();
    return userContext.isProUser? "/ArtistDashboard" : "/Landing";
}

export const detectDesktop = () => {
    const browser = detect();
    const browserOs = (browser!=null&&browser.os !=null) ?(browser.os).toLocaleLowerCase().split(" ") : null;
    // console.log( browserOs );
    const isDesktop = (browserOs == null) ? true : !(browserOs.includes('android') || browserOs.includes('ios') 
        // || ( browserOs.includes('mac') && browserOs.includes('os') )
    );
    return isDesktop;
    // return false
}

export const detectWebView = () => {
    const browser = detect();
    
    const browserName = browser?.name
    const isConfirmedWebView = browserName=='chromium-webview' || 
                        browserName=='facebook' || 
                        browserName=='instagram' || 
                        browserName=='ios-webview'
                         ? true : false

    const isPotentialWebView = browserName=='ios' || browserName=='samsung' ? true : false

    return { isConfirmedWebView, isPotentialWebView, os: browser?.os }
}