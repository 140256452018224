'use client'
import React, { ReactNode, useEffect } from 'react';

import { Container, Box } from "@mui/material";
import { SurfacePrimary } from "../../common/color";
import LandscapeDialog from "../LandscapeDialog";
// import { detect } from "detect-browser";
import { useAuthContext } from '@/app/context/AuthContext';
import { useRouter, usePathname } from 'next/navigation';
// import { detectDesktop } from '@/app/common/Util';
import { OrientationStatus } from '@/app/hooks/OrientationStatus';
import { useDeviceRestriction } from "@/app/hooks/DeviceRestriction";

interface BlankCanvasProps {
  children: ReactNode;
}


const BlankAbsoluteCanvas: React.FC<BlankCanvasProps> = ({ children }) => {
  // const browser = detect();
  // const browserOs = (browser!=null&&browser.os !=null) ?(browser.os).toLocaleLowerCase().split(" ") : null;
  // const isDesktop = (browserOs == null) ? true : !(browserOs.includes('android') || browserOs.includes('ios'));
  // const isDesktop = detectDesktop();
  const { user, loading } = useAuthContext();
  const router = useRouter();
  const pathname = usePathname();
  const { allowedDevice } = useDeviceRestriction();
  
  useEffect(() => {
    if( 
      allowedDevice &&
      pathname.indexOf('signin') == -1 && 
      pathname.indexOf('Register') == -1 &&
      !loading &&
      (user==null || ( user !== null && !user.idToken)) ){
        console.log('should redirect to signin')
      router.push('/signin');
    } else if ( 
      allowedDevice &&
      (pathname == '/' || pathname.indexOf('signin') > -1 || pathname.indexOf('Register') > -1) && 
      !loading &&
      user && user.idToken ){
      router.push('/Landing');
    }
  }, [user, loading]);

  const { isLandscape } = OrientationStatus();

  return (
    (allowedDevice && isLandscape) ? (<LandscapeDialog />) :
    !allowedDevice ? <p>I am afraid only mobile phone is supported at the moment.</p> : (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container
          maxWidth="sm"
          disableGutters
          sx={{
            justifyContent: "center",
            width: "100%",
            display: "flex",
            height: "auto",
            padding: "0",
            margin: "0",
            background: SurfacePrimary,
          }}
        >
          {children}
        </Container>
      </Box>
    ))
  };

export default BlankAbsoluteCanvas;
